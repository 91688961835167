import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0 !important",
    // paddingTop: "16px !important",
    "& input": {
      width: "100% !important",
    },
    "& .special-label": {
      left: "10px !important",
      color: "#666666 !important",
    },
    "&:focus-within": {
      "& .special-label": {
        color: "#197cdf",
      },
    },
  },
  hasError: {
    "& input": {
      borderColor: "#d32f2f !important",
      color: "#d32f2f !important",
      "&:focus": {
        borderColor: "#d32f2f !important",
        boxShadow: "0 0 0 1px #d32f2f !important",
      },
    },
    "& .special-label": {
      color: "#d32f2f !important",
    },
    "&:focus-within": {
      "& .special-label": {
        color: "#d32f2f",
      },
    },
  },
}));
