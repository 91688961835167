import { withJsonFormsControlProps } from "@jsonforms/react";

import AddressInput from "../CustomInput";

const AddressInputControl = ({
  data,
  handleChange,
  path,
  uischema,
  errors,
}) => {
  return (
    <AddressInput
      errors={errors}
      label={uischema.label}
      description={uischema.description}
      updateValue={(newValue) => handleChange(path, newValue)}
      value={data}
    />
  );
};
export default withJsonFormsControlProps(AddressInputControl);
