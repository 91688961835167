//===================================================
// 1.Load,Init,Settings
//===================================================
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  DEEP_CLEAN,
  END_OF_LEASE,
  GENERAL_CLEANING,
  NUMBER_OF_BATHROOMS,
  NUMBER_OF_ROOMS,
} from "../../../common/Constants";
import { checkPhoneNumber, validateValidEmail } from "../../../common/Utility";
import {
  GetAuthValues,
  setIsLoginSuccess as setIsLoginSuccessAction,
} from "../../../redux/modules/Auth";
import { setSelectedAddress as setSelectedAddressAction } from "../../../redux/modules/Book";

import { format } from "date-fns";

import appStoreButton from "../../../assets/btn-app-store.png";
import googlePlayButton from "../../../assets/btn-google-play.png";

// Material-ui
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";

// Material-ui icon
import { ExpandMore } from "@mui/icons-material";

// custom components
import { BookingSummary, InformationForm } from "..";
import {
  CustomDateTimePicker,
  CustomSearchLocation,
  CustomSnackBarMessage,
  LoginModal,
  ManualAddressForm,
} from "../../atoms";

// css *required
import { useStyles } from "./index.css";

//===================================================
// 2.Main Component
//===================================================
const BookingForm = (props) => {
  // 2-1. useStyles *require
  const classes = useStyles();

  // 2-2. Store
  const { accessToken, userInfo, address } = GetAuthValues();
  const dispatch = useDispatch();
  const selectedAddress = useSelector((state) => state.book.selectedAddress);
  const setSelectedAddress = (address) =>
    dispatch(setSelectedAddressAction(address));
  const isLoginSuccess = useSelector((state) => state.auth.isLoginSuccess);
  const setIsLoginSuccess = (value) => setIsLoginSuccessAction(value);

  const listTotalHoursPerRoom = {
    1: 3,
    2: 3,
    3: 3,
    4: 4,
    5: 4,
  };
  const [isAgreeWithTermsAndPolicy, setIsAgreeWithTermsAndPolicy] =
    useState(false);
  const [showDateTime, setShowDateTime] = useState(false);
  const [isShowTypeOfArea, setIsShowTypeOfArea] = useState(false);
  const [selectedEnquiry, setSelectedEnquiry] = useState("");
  const [selectedAddons, setSelectedAddons] = useState("1"); //0. end of lease 1. general cleaning
  const [isShowNumberOfRooms, setIsShowNumberOfRooms] = useState(false);
  const [isShowInformation, setIsShowInformation] = useState(false);
  const [numberOfRoomsStudio, setNumberOfRoomsStudio] = useState(1);
  const [numberOfBathRoom, setNumberOfBathRoom] = useState(1);
  const [totalCost, setTotalCost] = useState("");
  const [totalCharge, setTotalCharge] = useState(0);
  const [totalHours, setTotalHours] = useState(
    listTotalHoursPerRoom[numberOfRoomsStudio]
  );
  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [serviceInfo, setServiceInfo] = useState({
    room: 1,
    bathroom: 1,
  });
  const [isDisabledFields, setIsDisabledFields] = useState(false);
  const [addons, setAddons] = useState([]);

  // 2-2. Store
  const currentDate = new Date();
  const currentHour = currentDate.getHours();

  let addDate = 1;
  if (currentHour >= 23) {
    addDate = 2;
  }

  currentDate.setDate(currentDate.getDate() + addDate);
  const [preparedData, setPreparedData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    fullAddress: "",
    street: "",
    suburb: "",
    state: "",
    country: "",
    zipcode: "",
    longitude: "",
    latitude: "",
    bookingDateTime: format(
      new Date(
        new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate(),
          7,
          0
        )
      ),
      "yyyy-MM-dd hh:mm a"
    ),
    service: "",
    serviceInfo: "",
    bookingStatus: "",
    total: "",
    bookingFrom: "",
    notes: "",
    addOns: "",
    numberOfHours: "",
  });
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false); //
  const [showManualAddress, setShowManualAddress] = useState(false);
  const [manualAddress, setManualAddress] = useState({});
  const [expanded, setExpanded] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    // Remove empty value pairs
    setPreparedData((data) => {
      const newData = { ...data };
      for (const [key, value] of Object.entries(newData)) {
        if (!value || value.toString().trim() === "") {
          delete newData[key];
        }
      }
      return newData;
    });
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      const sendBooking = async () => {
        try {
          setIsLoading(true);
          const response = await axios.post("/api/booking", preparedData);
          if (response.status === 201) {
            if (preparedData?.note) {
              await axios.post(
                `/api/booking/booking-notes/${response?.data?.invoice?.bookingId}`,
                { bookingNote: preparedData?.note }
              );
            }

            await axios.post("/api/calendar", {
              bookingId: response?.data?.booking?._id,
            });

            await axios.post("/api/notification/booking", {
              bookingId: response?.data?.booking?._id,
            });

            navigate("/booking/" + response?.data?.invoice?._id, {
              state: { bookingId: response?.data?.booking?._id },
            });
          }

          setIsLoading(false);
          setFormErrors({ message: response.data.error });
        } catch (error) {
          setFormErrors({ message: error.response.data.message });
          console.log(error);
        }
      };
      sendBooking();
    }
  }, [formErrors, isSubmit]);

  useEffect(() => {
    if (selectedAddress !== "" && accessToken) {
      setShowDateTime(true);
    }
  }, [selectedAddress, accessToken]);

  useEffect(() => {
    if (!accessToken) {
      setShowDateTime(false);
      setIsShowInformation(false);
      setIsShowTypeOfArea(false);
      setIsShowNumberOfRooms(false);
      setSelectedAddress({
        fullAddress: "",
        street: "",
        suburb: "",
        state: "",
        country: "",
        zipcode: "",
        longitude: "",
        latitude: "",
      });
      setSelectedEnquiry("");
      setAddons([]);
      setIsDisabledFields(false);
    }

    if (accessToken) {
      setShowDateTime(true);
      setPreparedData({
        ...preparedData,
        ...address[0],
        firstName: userInfo.firstname,
        lastName: userInfo.lastname,
        phone: userInfo.phone,
        email: userInfo.email,
      });
      setSelectedAddress({ ...selectedAddress, ...address[0] });
      setIsDisabledFields(true);
    }
  }, [accessToken]);

  useEffect(() => {
    if (showManualAddress) {
      const { state, zipcode, street_address, suburb } = selectedAddress;
      if (
        state !== "" &&
        zipcode !== "" &&
        street_address !== "" &&
        suburb !== ""
      ) {
        setShowDateTime(true);
      }
    }
  }, [selectedAddress, showManualAddress]);

  // showMain
  return showMain({
    ...props,
    classes,
    showDateTime,
    setShowDateTime,
    isShowTypeOfArea,
    setIsShowTypeOfArea,
    isShowNumberOfRooms,
    setIsShowNumberOfRooms,
    numberOfRoomsStudio,
    setNumberOfRoomsStudio,
    numberOfBathRoom,
    setNumberOfBathRoom,
    totalHours,
    setTotalHours,
    listTotalHoursPerRoom,
    isShowInformation,
    setIsShowInformation,
    totalCost,
    setTotalCost,
    isLoading,
    setIsLoading,
    formErrors,
    setFormErrors,
    isSubmit,
    setIsSubmit,
    preparedData,
    setPreparedData,
    isLoginModalOpen,
    setIsLoginModalOpen,
    isLoginSuccess,
    setIsLoginSuccess,
    accessToken,
    showManualAddress,
    setShowManualAddress,
    manualAddress,
    setManualAddress,
    isAgreeWithTermsAndPolicy,
    setIsAgreeWithTermsAndPolicy,
    selectedAddress,
    setSelectedAddress,
    serviceInfo,
    setServiceInfo,
    selectedEnquiry,
    setSelectedEnquiry,
    selectedAddons,
    setSelectedAddons,
    addons,
    setAddons,
    isDisabledFields,
    setIsDisabledFields,
    totalCharge,
    setTotalCharge,
    expanded,
    setExpanded,
  });
};

//===================================================
// 3.Export
//===================================================
export default BookingForm;

//===================================================
// 4.propTypes and defaultProps
//===================================================

//4-1. propTypes
BookingForm.propTypes = {};

//4-2. defaultProps
BookingForm.defaultProps = {};

//===================================================
// 5.Functions *require showMain()
//===================================================
/**
 * Show main
 * @param {*} props
 */

const handleExpand = (panel, props) => (event, newExpanded) => {
  const { setExpanded } = props;
  setExpanded(newExpanded ? panel : false);
};

const showMain = (props) => {
  const {
    classes,
    showDateTime,
    isShowTypeOfArea,
    isShowNumberOfRooms,
    numberOfRoomsStudio,
    isShowInformation,
    totalHours,
    setTotalCost,
    totalCost,
    isLoading,
    formErrors,
    isLoginSuccess,
    setIsLoginSuccess,
    showManualAddress,
    preparedData,
    setPreparedData,
    setIsAgreeWithTermsAndPolicy,
    selectedAddress,
    setSelectedAddress,
    selectedEnquiry,
    selectedAddons,
    addons,
    setTotalHours,
    isDisabledFields,
    setTotalCharge,
    totalCharge,
    expanded,
    setExpanded,
  } = props;
  return (
    <React.Fragment>
      <CustomSnackBarMessage
        open={isLoginSuccess}
        handleClose={() => setIsLoginSuccess(false)}
        message="Login successful"
        severity="success"
        variant="filled"
      />

      <Grid container spacing={2} sx={{ px: "0 !important", mt: 2 }}>
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent>
              <CustomSearchLocation
                handleShowDateTime={() => handleShowDateTime(props)}
                setAddress={(address) =>
                  setSelectedAddress({ ...selectedAddress, ...address })
                }
                selectedAddress={
                  !showManualAddress ? selectedAddress.fullAddress : ""
                }
                hasError={formErrors.address}
                setShowManualAddress={() => {
                  handleShowManualAddress(props);
                }}
                showManualAddress={showManualAddress}
                type="home"
              >
                {showManualAddress && (
                  <Grid container spacing={2} sx={{ p: 0 }}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      sx={{ mt: 5 }}
                      className={classes.customPaddingTop}
                    >
                      <ManualAddressForm
                        street={selectedAddress.street}
                        suburb={selectedAddress.suburb}
                        state={selectedAddress.state}
                        zipcode={selectedAddress.zipcode}
                        country={selectedAddress.country}
                        setAddress={(address) =>
                          setSelectedAddress({ ...selectedAddress, ...address })
                        }
                        hasError={formErrors}
                      />
                    </Grid>
                  </Grid>
                )}
              </CustomSearchLocation>
              {!isDisabledFields && (
                <Typography
                  variant="body1"
                  color="primary"
                  align="center"
                  sx={{
                    mb: 0,
                    p: 0,
                    mt: 1,
                    cursor: isDisabledFields ? "not-allowed" : "pointer",
                    display: {
                      xs: "block",
                      sm: "block",
                      md: "none",
                      lg: "none",
                      xl: "none",
                    },
                  }}
                  onClick={() => {
                    handleShowManualAddress(props);
                  }}
                >
                  {showManualAddress
                    ? "Search address"
                    : "Enter address manually"}
                </Typography>
              )}

              <Grid container spacing={2} sx={{ p: 0 }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{ mt: showManualAddress ? 1 : 3 }}
                >
                  {showDateTime ? (
                    <CustomDateTimePicker
                      handleShowTypeOfArea={() => handleShowTypeOfArea(props)}
                      isShowTypeOfArea={isShowTypeOfArea}
                      setSelectedDateTime={(dateTime) =>
                        setPreparedData({
                          ...preparedData,
                          bookingDateTime: dateTime,
                        })
                      }
                    />
                  ) : null}
                </Grid>
              </Grid>
              {isShowTypeOfArea ? showTypeOfArea(props) : null}
              {/* {isShowNumberOfRooms ? showNumberOfRoomsAndBathroom(props) : null} */}
              {selectedEnquiry === "residential" ||
              selectedEnquiry === "commercial"
                ? showResidentialForm(props)
                : null}

              {isShowInformation ? (
                <React.Fragment>
                  <Divider variant="fullWidth" />
                  <Box sx={{ mt: 2 }} align="left">
                    <InformationForm
                      isShowInformation={isShowInformation}
                      isLoading={isLoading}
                      setTermsAndPolicy={(isAgree) =>
                        setIsAgreeWithTermsAndPolicy(isAgree)
                      }
                      setInformation={(info) =>
                        setPreparedData({ ...preparedData, ...info })
                      }
                      handleSubmitBooking={(data) => handleSubmitBooking(props)}
                      userInfo={preparedData}
                      hasError={formErrors}
                      disabled={isDisabledFields}
                    />
                  </Box>
                </React.Fragment>
              ) : null}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container sx={{ marginBottom: "1rem" }}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  flexShrink: 0,
                  fontWeight: 1000,
                  fontSize: 18,
                }}
              >
                Book on our app and get $10 OFF
              </Typography>
            </Grid>
            <Grid item xs={6} mt={2}>
              <a href="https://apps.apple.com/au/app/cleaningly/id1621797423">
                <img
                  src={appStoreButton}
                  alt="Get it on the App Store"
                  width="95%"
                />
              </a>
            </Grid>
            <Grid item xs={6} mt={2}>
              <a href="https://play.google.com/store/apps/details?id=com.tudlo.cleaningly_mobile">
                <img
                  src={googlePlayButton}
                  alt="Get it on Google Play"
                  width="95%"
                />
              </a>
            </Grid>
          </Grid>
          <Box>
            {isShowNumberOfRooms ? (
              <BookingSummary
                service={preparedData?.service}
                totalRoomsStudio={numberOfRoomsStudio}
                totalHours={totalHours}
                totalCost={totalCost}
                totalCharge={totalCharge}
                setTotalCost={(cost) => setTotalCost(cost)}
                setTotalCharge={(charge) => setTotalCharge(charge)}
                setTotalHours={(hours) => setTotalHours(hours)}
                selectedAddons={selectedAddons}
                listGeneralAddons={addons}
                isEndOfLease={selectedAddons === "0" ? true : false}
                isDeepClean={selectedAddons === "6" ? true : false}
                bookingDate={preparedData?.bookingDateTime}
              />
            ) : null}
          </Box>
          <Accordion
            expanded={expanded === "note"}
            onChange={handleExpand("note", props)}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="note-content"
              id="note"
            >
              <Typography
                sx={{ width: "100%", flexShrink: 0, fontWeight: 600 }}
              >
                Notes:
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" gutterBottom>
                Our price covers the cost of labour, equipment, supplies,
                insurance, profit margin, fees and management utilised in
                delivering the cleaning service to you.
              </Typography>
              <Typography variant="body1" gutterBottom>
                Please informed us about nearby parking spot otherwise we charge
                for parking fees.
              </Typography>
              <Typography variant="body1" gutterBottom>
                Other services such as outside window cleaning, carpet steam
                cleaning, oven cleaning are always extras. We will charge extra
                accordingly.
              </Typography>
              <Typography variant="body1" gutterBottom>
                If you wish to cancel or postpone the booked appointment, give
                us a call or message us immediately or we charge cancellation
                fee of $50.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "members"}
            onChange={handleExpand("members", props)}
            sx={{ mb: 3 }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="members-content"
              id="members"
            >
              <Typography sx={{ width: "80%", flexShrink: 0, fontWeight: 600 }}>
                Cleaningly Exclusive Club members enjoy:
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" gutterBottom>
                Priority bookings
              </Typography>
              <Typography variant="body1" gutterBottom>
                Priority Customer support handling
              </Typography>
              <Typography variant="body1" gutterBottom>
                Discounted Member rates
              </Typography>
              <Typography variant="body1" gutterBottom>
                Exclusive deals
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Grid item xs={12} mt={2}>
            <Link
              underline="always"
              sx={{
                margin: "auto",
              }}
              target={"_blank"}
              href="https://www.facebook.com/messages/t/CleaninglyHomeServices"
            >
              Chat with our Customer Happiness Officer or
            </Link>
            <Link
              underline="always"
              sx={{
                marginLeft: 1,
              }}
              href="tel:0480 039 477"
            >
              Call: 0480 039 477
            </Link>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Link
              underline="always"
              sx={{
                margin: "auto",
              }}
              target={"_blank"}
              href="https://www.cleaningly.com.au/terms-conditions"
            >
              Terms of Use and Cancellation Policy
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

/**
 * Show type of area
 * @param {*} props
 * @returns
 */
const showTypeOfArea = (props) => {
  const { classes } = props;
  return (
    <Container
      align="left"
      sx={{ mt: 1 }}
      className={classes.noPaddingLeft}
      maxWidth={false}
    >
      <FormControl>
        <FormLabel
          id="type-of-area"
          sx={{ fontSize: { xs: 14, sm: 14, md: 16, lg: 16, xl: 16 } }}
        >
          What is the type of your enquiry?
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="type-of-area"
          name="row-radio-buttons-group"
          onChange={(e) => handleSelectedTypeOfArea(e.target.value, props)}
        >
          <FormControlLabel
            value="residential"
            control={<Radio />}
            label="Residential"
          />
          <FormControlLabel
            value="commercial"
            control={<Radio />}
            label="Commercial"
          />
        </RadioGroup>
      </FormControl>
    </Container>
  );
};

/**
 * Show number of rooms and bathroom selection
 * @param {*} props
 * @returns
 */
const showNumberOfRoomsAndBathroom = (props) => {
  const {
    classes,
    numberOfRoomsStudio,
    serviceInfo,
    setServiceInfo,
    selectedAddons,
  } = props;
  return (
    <Container align="left" sx={{ mt: 1 }} className={classes.noPaddingLeft}>
      <FormControl sx={{ mt: 1, mr: 1, mb: 1, minWidth: 120 }}>
        <InputLabel id="state">No. of Room</InputLabel>
        <Select
          labelId="numberRoom"
          id="numberRoom"
          name="numberRoom"
          value={numberOfRoomsStudio}
          label="No. of Room"
          onChange={(event) => getTotalHours(event.target.value, props)}
        >
          {NUMBER_OF_ROOMS.map((number) => {
            if (number === 1 && selectedAddons === "2") {
              return null;
            } else {
              return (
                <MenuItem key={number} value={number}>
                  {number}
                </MenuItem>
              );
            }
          })}
        </Select>
      </FormControl>
      <FormControl sx={{ mt: 1, mr: 1, mb: 1, minWidth: 120 }}>
        <InputLabel id="state">No. of Bathroom</InputLabel>
        <Select
          labelId="numberBathRooom"
          id="numberBathRooom"
          name="numberBathRooom"
          value={serviceInfo.bathroom}
          label="No. of Bathroom"
          onChange={(event) => {
            setServiceInfo({
              ...serviceInfo,
              ...{ bathroom: event.target.value },
            });
          }}
        >
          {NUMBER_OF_BATHROOMS.map((number) => (
            <MenuItem key={number} value={number}>
              {number}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Container>
  );
};

/**
 * show form for residential
 * @param {*} props
 */
const showResidentialForm = (props) => {
  const { selectedAddons } = props;
  return (
    <React.Fragment>
      {showNumberOfRoomsAndBathroom(props)}
      {showResidentialAddOns(props)}
      {/* {selectedAddons === "0" ? showEndOfLease(props) : null} */}
      {selectedAddons === "1" || selectedAddons === "7"
        ? showGeneralCleaning(props)
        : null}
      {/* {selectedAddons === "6" ? showDeepClean(props) : null} */}
    </React.Fragment>
  );
};

/**
 * show residential addons
 * @param {*} props
 */
const showResidentialAddOns = (props) => {
  const { selectedAddons, selectedEnquiry } = props;
  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="addons"
        defaultValue={1}
        value={selectedAddons}
        name="addOns"
        onChange={(e) => handleOnChangeSelectedAddons(e.target.value, props)}
      >
        <FormControlLabel value={0} control={<Radio />} label="End of lease" />
        {selectedEnquiry === "commercial" ? (
          <FormControlLabel
            value={7}
            control={<Radio />}
            label="Commercial Clean"
          />
        ) : (
          <FormControlLabel
            value={1}
            control={<Radio />}
            label="General Cleaning"
          />
        )}
        <FormControlLabel
          value={2}
          control={<Radio />}
          label="Carpet Steam Cleaning"
        />
        <FormControlLabel
          value={3}
          control={<Radio />}
          label="Property Services"
        />
        <FormControlLabel
          value={4}
          control={<Radio />}
          label="Rubbish Removal"
        />
        <FormControlLabel value={5} control={<Radio />} label="Gardening" />
        <FormControlLabel value={6} control={<Radio />} label="Deep Clean " />
      </RadioGroup>
    </FormControl>
  );
};

/**
 * end of lease details
 * @param {*} props
 * @returns
 */
const showEndOfLease = (props) => {
  return (
    <List dense={true}>
      {END_OF_LEASE.map((value) => {
        return (
          <ListItem key={value.price}>
            <ListItemText primary={value.text} />
          </ListItem>
        );
      })}
    </List>
  );
};

/**
 * Deep Clean details
 * @param {*} props
 * @returns
 */
const showDeepClean = (props) => {
  return (
    <List dense={true}>
      {DEEP_CLEAN.map((value) => {
        return (
          <ListItem key={value.price}>
            <ListItemText primary={value.text} />
          </ListItem>
        );
      })}
    </List>
  );
};

const showGeneralCleaning = (props) => {
  return (
    <FormControl sx={{ width: "100%" }}>
      <FormLabel
        id="type-of-area"
        sx={{ fontSize: { xs: 14, sm: 14, md: 16, lg: 16, xl: 16 } }}
      >
        Addons
      </FormLabel>
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {GENERAL_CLEANING.map((value, index) => {
          return (
            <Grid container key={index}>
              {value.map((details, index) => {
                return (
                  <Grid key={index} item xs={12} md={6}>
                    <ListItem disablePadding disableGutters>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          value={details.text}
                          tabIndex={-1}
                          disableRipple
                          onChange={(event) =>
                            handleGeneralCleaningAddons(event, props)
                          }
                        />
                      </ListItemIcon>
                      {/* <ListItemText
                        primary={`${details.text} (+$${details.price})`}
                      /> */}
                      <ListItemText
                        primary={`${details.text}`}
                      />
                    </ListItem>
                  </Grid>
                );
              })}
            </Grid>
          );
        })}
      </List>
    </FormControl>
  );
};

/**
 * Get total hours per room/studio
 * @param {*} totalRoomsStudio
 * @param {*} props
 */
const getTotalHours = async (totalRoomsStudio, props) => {
  const {
    setNumberOfRoomsStudio,
    setTotalHours,
    listTotalHoursPerRoom,
    setPreparedData,
    preparedData,
    totalCost,
    serviceInfo,
    setServiceInfo,
  } = props;
  setNumberOfRoomsStudio(totalRoomsStudio);
  setTotalHours(listTotalHoursPerRoom[totalRoomsStudio]);
  setServiceInfo({ ...serviceInfo, ...{ room: totalRoomsStudio } });
  setPreparedData({
    ...preparedData,
    total: totalCost.replace(/\$/g, ""),
  });

  if (Array.isArray(GENERAL_CLEANING)) {
    const selectedAddons = GENERAL_CLEANING.flatMap((x) => x).find(
      (val) => val.text === "Carpet Steam Cleaning"
    );
    selectedAddons.price = Number(50 * totalRoomsStudio);
  }
};

/**
 * Validate form
 * @param {*} preparedData
 * @returns
 */
const validateForm = (preparedData, props) => {
  const { showManualAddress, isAgreeWithTermsAndPolicy, selectedAddress } =
    props;
  const errors = {};
  if (showManualAddress) {
    if (!selectedAddress.street) {
      errors.street = "Please enter street address.";
    }

    if (!selectedAddress.suburb) {
      errors.suburb = "Please enter a suburb.";
    }

    if (!selectedAddress.state) {
      errors.state = "Please enter a state.";
    }

    if (!selectedAddress.zipcode) {
      errors.zipcode = "Please enter a postcode.";
    } else if (
      !Number(selectedAddress.zipcode) ||
      selectedAddress.zipcode.length > 4
    ) {
      errors.zipcode = "Please enter a valid postcode.";
    }
  } else {
    if (!selectedAddress.fullAddress) {
      errors.address = "Please select location.";
    }
  }

  if (!preparedData.email) {
    errors.email = "Please enter your email.";
  } else if (!validateValidEmail(preparedData.email)) {
    errors.email = "Please enter a valid email.";
  }

  if (!preparedData.firstName) {
    errors.firstName = "Please enter your firstname.";
  }

  if (!preparedData.lastName) {
    errors.lastName = "Please enter your lastname.";
  }

  if (!checkPhoneNumber(preparedData.phone)) {
    errors.phoneNumber = "Please enter a valid phone number.";
  }

  if (!isAgreeWithTermsAndPolicy) {
    errors.agree = "You must agree with the terms and conditions";
  }
  return errors;
};

//===================================================
// 6.Actions
//===================================================

/**
 * Handle Show Date Time
 * @param {*} message
 * @param {*} props
 */
const handleShowDateTime = (props) => {
  const { setShowDateTime } = props;
  setShowDateTime(true);
};

/**
 * Handle Show Type of Area
 * @param {*} message
 * @param {*} props
 */
const handleShowTypeOfArea = (props) => {
  const { setIsShowTypeOfArea } = props;
  setIsShowTypeOfArea(true);
};

/**
 * Handle selected type of area
 * @param {*} message
 * @param {*} props
 */
const handleSelectedTypeOfArea = (value, props) => {
  const {
    setIsShowNumberOfRooms,
    setTotalHours,
    setNumberOfRoomsStudio,
    setIsShowInformation,
    setPreparedData,
    preparedData,
    serviceInfo,
    setServiceInfo,
    setSelectedEnquiry,
    setSelectedAddons,
  } = props;
  setIsShowInformation(true);
  setSelectedEnquiry(value);

  setPreparedData({
    ...preparedData,
    service: value,
  });

  if (value === "residential") {
    setSelectedAddons("1");
  } else {
    setSelectedAddons("7");
  }

  setIsShowNumberOfRooms(true);
  setNumberOfRoomsStudio(1);
  setTotalHours(3);
  setServiceInfo({ ...serviceInfo, ...{ room: 1, bathroom: 1 } });
};

async function handleSubmitBooking(props) {
  const {
    totalCost,
    setFormErrors,
    preparedData,
    setPreparedData,
    setIsSubmit,
    selectedAddress,
    serviceInfo,
    addons,
    totalHours,
    selectedAddons,
  } = props;
  let typeOfService = "";
  if (selectedAddons === "0") {
    typeOfService = "End of Lease";
  }

  if (selectedAddons === "1") {
    typeOfService = "General House Cleaning";
  }

  if (selectedAddons === "2") {
    typeOfService = "Carpet Steam Cleaning";
  }

  if (selectedAddons === "3") {
    typeOfService = "Property Services";
  }

  if (selectedAddons === "4") {
    typeOfService = "Rubbish Removal";
  }

  if (selectedAddons === "5") {
    typeOfService = "Gardening";
  }

  if (selectedAddons === "6") {
    typeOfService = "Deep Clean";
  }

  if (selectedAddons === "7") {
    typeOfService = "Commercial Clean";
  }

  setPreparedData({
    ...preparedData,
    ...selectedAddress,
    serviceInfo: JSON.stringify(serviceInfo),
    total: totalCost.replace(/\$/g, ""),
    addOns: JSON.stringify(addons),
    numberOfHours: totalHours,
    serviceType: typeOfService,
  });
  setFormErrors(validateForm(preparedData, props));
  setIsSubmit(true);
}

/**
 * Handle close login modal
 * @param {*} props
 */
const handleCloseLoginModal = (props) => {
  const { setIsLoginModalOpen } = props;
  setIsLoginModalOpen(false);
};

/**
 * show/hide manual address form
 * @param {*} props
 */
const handleShowManualAddress = (props) => {
  const { setShowManualAddress, showManualAddress } = props;
  setShowManualAddress(!showManualAddress);
};

/**
 * handle change for general cleaning
 * @param {*} event
 * @param {*} props
 */
const handleGeneralCleaningAddons = (event, props) => {
  const { addons, setAddons } = props;
  const value = event.target.value;
  const selectedAddons = GENERAL_CLEANING.flatMap((x) => x).find(
    (val) => val.text === value
  );
  const currentAddons = [...addons];
  if (event.target.checked) {
    currentAddons.push(selectedAddons);
    setAddons(currentAddons);
  } else {
    const filteredAddons = currentAddons.filter(
      (addons) => addons.text !== value
    );
    setAddons(filteredAddons);
  }
};

/**
 * handle onchange selected addons
 * @param {*} value
 * @param {*} props
 */
const handleOnChangeSelectedAddons = (value, props) => {
  const {
    setSelectedAddons,
    setAddons,
    setTotalHours,
    setNumberOfRoomsStudio,
    setServiceInfo,
    serviceInfo,
  } = props;
  setSelectedAddons(value);
  setAddons([]);
  setTotalHours(3);
  if (value === "2") {
    setNumberOfRoomsStudio(2);
    setServiceInfo({ ...serviceInfo, ...{ room: 2 } });
  }
  if (value === "3") {
    setTotalHours(4);
    setNumberOfRoomsStudio(1);
  }
  if (value === "4") {
    setTotalHours(4);
    setNumberOfRoomsStudio(1);
  }
  if (value === "5") {
    setTotalHours(4);
    setNumberOfRoomsStudio(1);
  }
};
