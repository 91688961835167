import React, { useState } from "react";
// ** MUI Imports
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Card,
  Box,
  Typography,
} from "@mui/material";
import Close from "mdi-material-ui/Close";
import { usePaymentInputs } from "react-payment-inputs";
import axios from "axios";

const serverUrl = process.env.REACT_APP_BACKEND_URL;
axios.defaults.baseURL = serverUrl;

const PaymentInfoModal = ({ open, handleClose, totalPrice }) => {
  const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps } =
    usePaymentInputs();

  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvc, setCvc] = useState("");

  const handleChangeCardNumber = (e) => {
    setCardNumber(e.target.value);
  };
  const handleChangeExpiryDate = (e) => setExpiryDate(e.target.value);
  const handleChangeCvc = (e) => setCvc(e.target.value);
  const { erroredInputs, touchedInputs } = meta;

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(erroredInputs, touchedInputs);
    if (!cardNumber.length > 0 && !expiryDate.length > 0 && !cvc.length > 0) {
      touchedInputs.cardNumber = true;
      touchedInputs.expiryDate = true;
      touchedInputs.cvc = true;
      erroredInputs.cardNumber = "Invalid Card Number";
      erroredInputs.expiryDate = "Invalid Expiry Date";
      erroredInputs.cvc = "Invalid CVC";
      return;
    }

    if (erroredInputs.cardNumber) {
      return;
    }
    if (meta.erroredInputs.expiryDate) {
      return;
    }
    if (meta.erroredInputs.cvc) {
      return;
    }

    console.log({ cardNumber, expiryDate, cvc });

    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography variant="h5">Pay Invoice</Typography>
        <Close
          fontSize="small"
          onClick={handleClose}
          sx={{
            cursor: "pointer",
            float: "right",
            marginTop: "5px",
            width: "20px",
          }}
        />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ px: 10 }}>
          <Grid item xs={12}>
            <Typography variant="h6">Enter Card Details</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              inputProps={getCardNumberProps({
                onChange: handleChangeCardNumber,
              })}
              value={cardNumber}
              placeholder="0000 0000 0000 0000"
              label="Card Number"
              fullWidth
              error={touchedInputs.cardNumber && erroredInputs.cardNumber}
              helperText={
                touchedInputs.cardNumber && erroredInputs.cardNumber
                  ? "Incorrect Card number"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Expiry date"
              variant="outlined"
              inputProps={getExpiryDateProps({
                onChange: handleChangeExpiryDate,
              })}
              value={expiryDate}
              inputRef={getExpiryDateProps().ref}
              fullWidth
              error={touchedInputs.expiryDate && erroredInputs.expiryDate}
              helperText={
                touchedInputs.expiryDate && erroredInputs.expiryDate
                  ? "Incorrect Expiry Date"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="CVC"
              placeholder="123"
              variant="outlined"
              inputProps={getCVCProps({ onChange: handleChangeCvc })}
              value={cvc}
              inputRef={getCVCProps().ref}
              error={erroredInputs.cvc && touchedInputs.cvc}
              helperText={
                erroredInputs.cvc && touchedInputs.cvc ? "Incorrect CVC" : ""
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            Total Price: {totalPrice}
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" onClick={handleSubmit}>
              Pay Now
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PaymentInfoModal;
