import { makeStyles } from "@mui/styles";
// import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme) => ({
  noBorder: {
    border: "none !important",
  },
  noPadding: {
    padding: "0 !important",
  },
  imageWidth: {
    width: "30% !important",
  },
  fullWith: {
    maxWidth: "96% !important",
    minHeight: "calc(100vh - 190px)",
  },
}));