//===================================================
// 1.Load,Init,Settings
//===================================================
import React from "react";

// Material-ui
import { Container } from "@mui/material";

// css *required
import { useStyles } from "./index.css";

//===================================================
// 2.Main Component
//===================================================
const Footer = (props) => {
  // 2-1. useStyles *require
  const classes = useStyles();

  // 2-2. Store

  // showMain
  return showMain({
    ...props,
    classes,
  });
};

//===================================================
// 3.Export
//===================================================
export default Footer;

//===================================================
// 4.propTypes and defaultProps
//===================================================

//4-1. propTypes
Footer.propTypes = {};

//4-2. defaultProps
Footer.defaultProps = {};

//===================================================
// 5.Functions *require showMain()
//===================================================
/**
 * Show main
 * @param {*} props
 */
const showMain = (props) => {
  const { classes } = props;
  return (
    <>
      <Container className={classes.footerNav}>
        {`Cleaningly Business Software v${process.env.REACT_APP_VERSION}`}
      </Container>
    </>
  );
};

//===================================================
// 6.Actions
//===================================================
