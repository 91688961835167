//===================================================
// 1.Load,Init,Settings
//===================================================
import { Alert, Snackbar } from "@mui/material";
import React from "react";

// Material-ui

// custom components

// css *required
import { useStyles } from "./index.css";

//===================================================
// 2.Main Component
//===================================================
const CustomSnackBarMessage = (props) => {
  // 2-1. useStyles *require
  const classes = useStyles();

  // 2-2. Store

  // showMain
  return showMain({
    ...props,
    classes,
  });
};

//===================================================
// 3.Export
//===================================================
export default CustomSnackBarMessage;

//===================================================
// 4.propTypes and defaultProps
//===================================================

//4-1. propTypes
CustomSnackBarMessage.propTypes = {};

//4-2. defaultProps
CustomSnackBarMessage.defaultProps = {};

//===================================================
// 5.Functions *require showMain()
//===================================================
/**
 * Show main
 * @param {*} props
 */
const showMain = (props) => {
  const { open, handleClose, message, severity, variant } = props;
  return (
    <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
      <Alert
        variant={variant}
        onClose={handleClose}
        severity={severity}
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

//===================================================
// 6.Actions
//===================================================
