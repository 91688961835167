//===================================================
// 1.Load,Init,Settings
//===================================================
import axios from "axios";
import React, { useEffect, useState } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAuthValues,
  GetOutsideAuthActions,
} from "../../../redux/modules/Auth";

// Material-ui
import {
  Button,
  Container,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
// Material-ui icon
import { LocationOnOutlined } from "@mui/icons-material";

// custom components
import { ManualAddressForm } from "../../atoms";
// css *required
import { Box } from "@mui/system";
import { useStyles } from "./index.css";

//===================================================
// 2.Main Component
//===================================================

const serverUrl = process.env.REACT_APP_BACKEND_URL;
axios.defaults.baseURL = serverUrl;

let showOption = false;
const CustomSearchLocation = (props) => {
  // 2-1. useStyles *require
  const classes = useStyles();

  // 2-2. Store
  const country = "au";
  const { ref: materialRef } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => {
      const { formatted_address } = place;
      const splittedAddress = formatted_address.split(", ");
      const splittedSecondAddress = splittedAddress[1].split(" ");
      const address = {
        zipcode: splittedSecondAddress.splice(-1)[0],
        state: splittedSecondAddress.splice(-1)[0],
        suburb: splittedSecondAddress.join(" "),
        fullAddress: formatted_address,
        longitude: place.geometry.location.lng(),
        latitude: place.geometry.location.lat(),
        country: "Australia",
        street: splittedAddress[0],
      };
      showNextStep(address, props);
    },
    inputAutocompleteValue: "country",
    options: {
      types: ["address"],
      componentRestrictions: { country },
    },
  });

  // showMain
  return showMain({
    ...props,
    classes,
    materialRef,
  });
};

//===================================================
// 3.Export
//===================================================
export default CustomSearchLocation;

//===================================================
// 4.propTypes and defaultProps
//===================================================

//4-1. propTypes
CustomSearchLocation.propTypes = {};

//4-2. defaultProps
CustomSearchLocation.defaultProps = {};

//===================================================
// 5.Functions *require showMain()
//===================================================
/**
 * Show main
 * @param {*} props
 */
const showMain = (props) => {
  const { type } = props;
  return type === "home"
    ? ShowSearchForHomePage(props)
    : ShowSearchForForm(props);
};

const showErrorMessage = (props) => {
  const { hasError } = props;
  return (
    <Container align="left">
      <Typography variant="caption" color="#d32f2f" gutterBottom>
        {hasError}
      </Typography>
    </Container>
  );
};

const ShowSearchForForm = (props) => {
  const { materialRef, hasError, setAddress } = props;
  const [addressInput, setAddressInput] = useState();

  const { placesService, placePredictions, getPlacePredictions } =
    usePlacesService({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,

      options: {
        types: ["address"],
        componentRestrictions: { country: "au" },
        input: addressInput,
      },
      debounce: 1000,
    });

  const onSelectAddress = (item) => {
    if (placePredictions.length)
      placesService?.getDetails(
        {
          placeId: item.place_id,
        },
        (placeDetails) => {
          const { formatted_address } = placeDetails;
          const splittedAddress = formatted_address.split(", ");
          const splittedSecondAddress = splittedAddress[1].split(" ");
          const address = {
            zipcode: splittedSecondAddress.splice(-1)[0],
            state: splittedSecondAddress.splice(-1)[0],
            suburb: splittedSecondAddress.join(" "),
            fullAddress: formatted_address,
            longitude: placeDetails?.geometry.location.lng(),
            latitude: placeDetails?.geometry.location.lat(),
            country: "Australia",
            street: splittedAddress[0],
          };
          setAddressInput(formatted_address);
          setAddress(address);
          getPlacePredictions({ input: "" });
        }
      );
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          zIndex: "1051 !important",
        }}
      >
        <TextField
          error={hasError ? true : false}
          helperText={hasError}
          sx={{ backgroundColor: "white", borderRadius: "15px" }}
          type="text"
          onChange={(e) => {
            setAddressInput(e.target.value);
            getPlacePredictions({ input: e.target.value });
          }}
          value={addressInput}
          placeholder="Search Address"
          fullWidth
        />
        {placePredictions?.length > 0 && (
          <Paper
            sx={{
              p: 2,

              my: 2,
            }}
          >
            <Typography
              variant="caption"
              sx={{ color: "gray", fontWeight: "bold" }}
            >
              Select location
            </Typography>
            {placePredictions?.map((item, index) => {
              return (
                <Typography
                  key={index}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      color: "white",
                      backgroundColor: "#4169e1",
                      borderRadius: "10px",
                    },
                    mt: 1,
                    p: 1,
                  }}
                  onClick={() => onSelectAddress(item)}
                >
                  {item.description}
                </Typography>
              );
            })}
          </Paper>
        )}
      </Box>
    </React.Fragment>
  );
};

const ShowSearchForHomePage = (props) => {
  const {
    classes,
    hasError,
    setHasError,
    materialRef,
    selectedAddress,
    showManualAddress,
    setAddress,
    readOnly,
    formErrors,
  } = props;
  const dispatch = useDispatch();

  let addreState = "";
  const addresses = useSelector((state) => state.auth.address);
  const token = useSelector((state) => state.auth.accessToken);
  if (addresses) {
    addreState = addresses[0]?.fullAddress;
    // setSelected(addresses[0]?._id);
  }
  const [stateAddresses, setStateAdresses] = useState(addresses);
  let selectedAddState = useSelector((state) => state.book.selectedAddress);
  const userId = useSelector((state) => state?.auth?.user?._id);
  const email = useSelector((state) => state?.auth?.user?.email);
  const password = localStorage.getItem("password");
  const [newAddress, setNewAddress] = useState("");
  const [showAddress, setShowAddress] = useState(false);
  const [addressError, setAddressError] = useState({});
  const [addressInput, setAddressInput] = useState("");

  const addressChange = (event) => {
    setNewAddress(event.target.value);
  };

  const { placesService, placePredictions, getPlacePredictions } =
    usePlacesService({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,

      options: {
        types: ["address"],
        componentRestrictions: { country: "au" },
        input: addressInput,
      },
      debounce: 1000,
    });

  const onSelectAddress = (item) => {
    if (placePredictions.length)
      placesService?.getDetails(
        {
          placeId: item.place_id,
        },
        (placeDetails) => {
          const { formatted_address } = placeDetails;
          const splittedAddress = formatted_address.split(", ");
          const splittedSecondAddress = splittedAddress[1].split(" ");
          const address = {
            zipcode: splittedSecondAddress.splice(-1)[0],
            state: splittedSecondAddress.splice(-1)[0],
            suburb: splittedSecondAddress.join(" "),
            fullAddress: formatted_address,
            longitude: placeDetails?.geometry.location.lng(),
            latitude: placeDetails?.geometry.location.lat(),
            country: "Australia",
            street: splittedAddress[0],
          };
          setAddressInput(formatted_address);
          showNextStep(address, props);
          getPlacePredictions({ input: "" });
        }
      );
  };

  const saveAddress = async (props) => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      userId: userId,
      fullAddress: selectedAddState.fullAddress,
      latitude: selectedAddState.latitude,
      longitude: selectedAddState.longitude,
      state: selectedAddState.state,
      street: selectedAddState.street,
      suburb: selectedAddState.suburb,
      country: selectedAddState.country,
      zipcode: selectedAddState.zipcode,
    };

    // check if address already exist
    if (
      stateAddresses.find((e) => e.fullAddress === selectedAddState.fullAddress)
    ) {
      setAddressError("Address already exist");
    } else {
      const response = await axios
        .post("/api/address", bodyParameters, config)
        .then(console.log)
        .catch(console.log);

      const optionUpdated = {
        _id: Math.random().toString(36).slice(-8),
        fullAddress: selectedAddState.fullAddress,
        latitude: selectedAddState.latitude,
        longitude: selectedAddState.longitude,
        state: selectedAddState.state,
        street: selectedAddState.street,
        suburb: selectedAddState.suburb,
        country: selectedAddState.country,
        zipcode: selectedAddState.zipcode,
      };

      let temp = [...stateAddresses, optionUpdated];
      setStateAdresses(temp);
    }
  };

  const handleChange = (event) => {
    var x = document.getElementById("searchLocation");
    if (event.target.value == "create_new") {
      setShowAddress(true);
      x.style.display = "block";
    } else {
      stateAddresses?.map((address) => {
        if (address._id == event.target.value) {
          setAddress({
            fullAddress: address.fullAddress,
            street: address.street,
            suburb: address.suburb,
            state: address.state,
            country: address.country,
            zipcode: address.zipcode,
            longitude: address.longitude,
            latitude: address.latitude,
          });
        }
      });
      x.style.display = "none";
      if (showManualAddress) {
        showManualAddressClick(props);
      }
      setShowAddress(false);
    }
  };

  const showManualAddressClick = (props) => {
    const { setShowManualAddress } = props;
    setShowManualAddress && setShowManualAddress();
  };
  const selectSavedAddressClick = (props) => {
    var x = document.getElementById("searchLocation");
    x.style.display = "none";
    setShowAddress(false);
    if (showManualAddress) {
      showManualAddressClick(props);
    }
  };
  return (
    <React.Fragment>
      <Box>
        <Container
          align="left"
          className={classes.containerLabel}
          maxWidth={false}
          sx={{ width: "100%" }}
        >
          <FormLabel
            id="input-place"
            sx={{
              fontSize: { xs: 14, sm: 14, md: 16, lg: 16, xl: 16 },
              fontWeight: "bold",
            }}
          >
            Where should the Cleaning Service be?
          </FormLabel>
          {userId && (
            <FormControl
              sx={{
                mt: 1,
                mb: 1,
                minWidth: "100%",

                width: "100%",
              }}
            >
              <Select
                onChange={handleChange}
                sx={{ backgroundColor: "white" }}
                inputProps={{ style: { width: 100 } }}
              >
                {addresses &&
                  stateAddresses?.map((address) => (
                    <MenuItem value={address._id}>
                      {address.fullAddress}
                    </MenuItem>
                  ))}

                <MenuItem value="create_new">Create New Address</MenuItem>
              </Select>
            </FormControl>
          )}
        </Container>
      </Box>
      {userId && (
        <Box
          justifyContent="center"
          className={classes.searchLocation}
          id="searchLocation"
        >
          <Paper
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
              px: 1,
              py: 0.5,
            }}
            className={hasError ? classes.hasError : ""}
          >
            <IconButton sx={{ p: "10px" }} aria-label="search">
              <LocationOnOutlined />
            </IconButton>
            <InputBase
              id="input-place"
              sx={{ ml: 1, flexGrow: 1 }}
              placeholder="Enter your location"
              inputProps={{
                "aria-label": "search google maps",
                placeholder: "Search your address",
              }}
              onChange={(e) => {
                setAddressInput(e.target.value);
                getPlacePredictions({ input: e.target.value });
              }}
              value={addressInput}
              readOnly={showManualAddress}
              disabled={readOnly}
              autoFocus
              required
            />
            <Divider
              sx={{
                height: 28,
                m: 0.5,
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                  xl: "block",
                },
              }}
              orientation="vertical"
            />
            {!readOnly && (
              <IconButton
                color="primary"
                sx={{
                  p: "10px",
                  fontSize: 14,
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                    lg: "block",
                    xl: "block",
                  },
                }}
                aria-label="directions"
                onClick={() => {
                  handleShowManualAddress(props);
                }}
              >
                {showManualAddress
                  ? "Search address"
                  : "Enter address manually"}
              </IconButton>
            )}
          </Paper>
          {placePredictions?.length > 0 && (
            <Paper
              sx={{
                p: 2,

                my: 2,
              }}
            >
              <Typography
                variant="caption"
                sx={{ color: "gray", fontWeight: "bold" }}
              >
                Select location
              </Typography>
              {placePredictions?.map((item, index) => {
                return (
                  <Typography
                    key={index}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        color: "white",
                        backgroundColor: "#4169e1",
                        borderRadius: "10px",
                      },
                      mt: 1,
                      p: 1,
                    }}
                    onClick={() => onSelectAddress(item)}
                  >
                    {item.description}
                  </Typography>
                );
              })}
            </Paper>
          )}
        </Box>
      )}
      {!userId && (
        <Box justifyContent="center">
          <Paper
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
              px: 1,
              py: 0.5,
            }}
            className={hasError ? classes.hasError : ""}
          >
            <IconButton sx={{ p: "10px" }} aria-label="search">
              <LocationOnOutlined />
            </IconButton>
            <InputBase
              id="input-place"
              sx={{ ml: 1, flexGrow: 1 }}
              placeholder="Enter your location"
              inputProps={{
                "aria-label": "search google maps",
                placeholder: "Search your address",
              }}
              onChange={(e) => {
                setAddressInput(e.target.value);
                getPlacePredictions({ input: e.target.value });
              }}
              value={addressInput}
              readOnly={showManualAddress}
              disabled={readOnly}
              autoFocus
              required
            />

            <Divider
              sx={{
                height: 28,
                m: 0.5,
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                  xl: "block",
                },
              }}
              orientation="vertical"
            />

            <IconButton
              color="primary"
              sx={{
                p: "10px",
                fontSize: 14,
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                  xl: "block",
                },
              }}
              aria-label="directions"
              onClick={() => {
                showManualAddressClick(props);
              }}
              saveManualAddressClick
            >
              {showManualAddress ? "Search address" : "Enter address manually"}
            </IconButton>
          </Paper>
          {placePredictions?.length > 0 && (
            <Paper
              sx={{
                p: 2,

                my: 2,
              }}
            >
              <Typography
                variant="caption"
                sx={{ color: "gray", fontWeight: "bold" }}
              >
                Select location
              </Typography>
              {placePredictions?.map((item, index) => {
                return (
                  <Typography
                    key={index}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        color: "white",
                        backgroundColor: "#4169e1",
                        borderRadius: "10px",
                      },
                      mt: 1,
                      p: 1,
                    }}
                    onClick={() => onSelectAddress(item)}
                  >
                    {item.description}
                  </Typography>
                );
              })}
            </Paper>
          )}
        </Box>
      )}
      {props.children}
      {showAddress && (
        <Button
          onClick={saveAddress}
          sx={{
            p: "10px",
            fontSize: 14,
            float: "right",
          }}
        >
          Save
        </Button>
      )}
      {showAddress && (
        <Button
          onClick={() => {
            selectSavedAddressClick(props);
          }}
          sx={{
            p: "10px",
            fontSize: 14,
            float: "right",
          }}
          color="primary"
        >
          Select Saved Address
        </Button>
      )}

      {addressError ? showErrorMessage(props) : null}
    </React.Fragment>
  );
};

//===================================================
// 6.Actions
//===================================================

/**
 * Show Nextstep Button
 * @param {*} message
 * @param {*} props
 */

const showNextStep = (place, props) => {
  const { handleShowDateTime, setAddress, type } = props;
  if (type !== "form") {
    handleShowDateTime && handleShowDateTime();
  }
  setAddress && setAddress(place);
};

/**
 * handle show manual address
 * @param {*} props
 */
const handleShowManualAddress = (props) => {
  const { setShowManualAddress, setAddress } = props;
  setAddress({
    fullAddress: "",
    street: "",
    suburb: "",
    state: "",
    country: "",
    zipcode: "",
    longitude: "",
    latitude: "",
  });
  setShowManualAddress && setShowManualAddress();
};
