import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Divider,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Container,
  Link,
  Paper,
} from "@mui/material";
const serverUrl = process.env.REACT_APP_BACKEND_URL;
axios.defaults.baseURL = serverUrl;

const BookingFinal = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [booking, setBooking] = useState(null);
  const [loading, setLoading] = useState(false);
  const [companyInfo, setCompanyInfo] = useState(null);

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const response = await axios.get(`/api/invoice/public/${id}`);
        setBooking(response?.data?.invoice?.bookingId);
        const companyRes = await axios.get("/api/company/public");
        setCompanyInfo(companyRes.data.company);

        if (!response?.data?.invoice?.bookingId) {
          navigate("/404");
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchInfo();
  }, [id, navigate]);

  const isPaid = booking?.isPaid;

  const handlePayRedirect = async () => {
    setLoading(true);
    const { data } = await axios.get(
      "/api/stripe/create-checkout/" + id + "?booking=true",
      {
        booking: true,
      }
    );
    setLoading(false);
    window.location.href = data?.redirect_url;
  };

  const handlePayLater = () => {
    if (!isPaid) {
      navigate("/thankyou");
    } else {
      navigate("/");
    }
  };

  const estimatedCost = Number((Number(booking?.total) / 110) * 100).toFixed(2);
  const totalCostGST = Number(booking?.total).toFixed(2);
  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 7, mb: 12 }}>
        <Paper
          background="light"
          sx={{ py: { xs: 4, md: 8 }, px: { xs: 3, md: 6 } }}
        >
          <Typography variant="h4" gutterBottom component="div">
            Booking Payment Information
          </Typography>
          <Grid container>
            <Grid item xs={6} sm={6}>
              <Typography variant="h6" gutterBottom component="div">
                Estimated Cost:
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography variant="h6" gutterBottom component="div">
                ${estimatedCost}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography variant="h6" gutterBottom component="div">
                Total Cost with GST:
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography variant="h6" gutterBottom component="div">
                ${totalCostGST}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} sx={{ marginTop: 10 }}>
              <Box>
                <div>
                  <img
                    src={companyInfo?.companyLogoUrl}
                    height="55px"
                    width="125px"
                    alt="cleaningly-logo"
                    style={{
                      objectFit: "contain",
                      objectPosition: "center",
                      borderRadius: "10px",
                    }}
                  />
                  <p style={{ margin: 0 }}>ABN: {companyInfo?.abn}</p>
                </div>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  Cleaningly Home Service Pty Ltd
                </Typography>
                <Typography variant="body2" sx={{ mb: 1, fontWeight: "bold" }}>
                  Call us at 0480 039 477
                </Typography>
              </Box>
              <Button
                variant="contained"
                onClick={handlePayLater}
                disabled={loading}
              >
                {isPaid
                  ? "Already Paid, Back to homepage"
                  : "Pay later and Complete the Booking now"}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ marginTop: 10 }}>
              <Button
                variant="contained"
                disabled={loading || isPaid}
                onClick={handlePayRedirect}
              >
                {isPaid ? "Already Paid" : "Pay now with Debit or Credit Card."}
              </Button>
              <Box>
                <Typography variant="caption" color="GrayText">
                  *After confirming the booking with our Customer Happiness
                  Officer, there may be additional fees or charges. However,
                  this is usually not the case if all of the information
                  provided was accurate.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Container>
  );
};

export default BookingFinal;
