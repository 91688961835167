/**
 * Get date & time
 * @param {*} value
 * @returns
 */
export const getDateTime = (value) => {
  const dateObj = new Date(value);
  const year = dateObj.getFullYear();
  const month = padZero(dateObj.getMonth() + 1);
  const date = padZero(dateObj.getDate());
  return year + "-" + month + "-" + date;
};

/**
 * Add zero to single digit time value
 * @param {*} value
 * @returns
 */
export const padZero = (value) => {
  if (value < 10) {
    return "0" + value;
  }
  return value;
};

/**
 * Capitalize first letter
 * @param {*} value
 * @returns
 */
export const toUpperCaseFirstLetter = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

/**
 * Check email validity
 * @param {*} email
 * @returns
 */
export const validateValidEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

/**
 * Check phone validity
 * @param {*} phoneNumber
 * @returns
 */
export const checkPhoneNumber = (phoneNumber) => {
  const regex = /^\+?[0-9]{11}|^\d{11}$/;
  return regex.test(phoneNumber);
};

/**
 *
 * @param {*} value
 * @returns
 */
export const validateEmailAndPhone = (value) => {
  const regex =
    /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})|(^[0-9]{11})+$/;
  return !regex.test(value);
};

/**
 * format phonenumber
 * @param {*} value
 * @returns
 */
export const loginBy = (value) => {
  if (!validateValidEmail(value)) {
    return `+61${value.slice(1)}`;
  }
  return value;
};
