import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  containerLabel: {
    paddingLeft: "0 !important",
    marginBottom: "1px !important",
  },
  hidden: {
    display: "none !important",
  },
  hasError: {
    border: "1px solid #d32f2f",
    boxShadow: "none !important",
  },
  searchLocation: {
    display: "none",
  },
}));
