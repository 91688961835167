import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Paper,
  Box,
  Typography,
  Link,
  Grid,
  Button,
} from "@mui/material";
import { PaymentOutlined } from "@mui/icons-material";
import axios from "axios";

const serverUrl = process.env.REACT_APP_BACKEND_URL;
axios.defaults.baseURL = serverUrl;

const PaymentSuccess = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const response = await axios.get(`/api/invoice/public/${id}`);
        setData(response.data.invoice);
        if (!response?.data?.invoice?.bookingId) {
          navigate("/");
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchInfo();
  }, [id]);

  console.log(data);

  const totalPrice = Number(data?.totalPrice?.replace("$", ""));
  // let gstPrice = (Number(data.totalPrice.replace("$", "")) * 0.1).toFixed(2);
  // let generalPrice = Number(data.totalPrice.replace("$", "")) - addOnsPrice;
  const isPaid = data?.bookingId?.isPaid;

  return (
    <Container align="center" maxWidth="sm">
      <Box sx={{ mt: 7, mb: 12 }}>
        <Paper
          background="light"
          sx={{ py: { xs: 4, md: 8 }, px: { xs: 3, md: 6 } }}
        >
          <PaymentOutlined color="success" sx={{ fontSize: 75 }} />
          <Typography variant="h5" gutterBottom component="div">
            Payment Successful
          </Typography>
          {data ? (
            <Box sx={{ mt: 4 }}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="h6" gutterBottom component="div">
                    Total Amount:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6" gutterBottom component="div">
                    ${totalPrice}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6" gutterBottom component="div">
                    Status:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6" gutterBottom component="div">
                    {isPaid ? "Paid" : "Not Paid"}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          ) : (
            "Loading..."
          )}
          <Typography variant="h6" gutterBottom component="div" sx={{ mt: 4 }}>
            Your payment has been confirmed.
          </Typography>
          <Link href="/">
            <Button variant="contained">Back to Homepage</Button>
          </Link>
        </Paper>
      </Box>
    </Container>
  );
};

export default PaymentSuccess;
