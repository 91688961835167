import React from "react";

const MessengerCustomerChat = require("react-messenger-customer-chat");
const CustomerChat = () => {
  return (
    <MessengerCustomerChat
      pageId={process.env.REACT_APP_FACEBOOK_PAGE_ID}
      appId={process.env.REACT_APP_FACEBOOK_APP_ID}
    />
  );
};

export default CustomerChat;
