//===================================================
// 1.Load,Init,Settings
//===================================================
import React, { useCallback, useState } from "react";
import { GetAuthValues } from "../../../redux/modules/Auth";
// Material-ui
import {
  Button,
  TextField,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Link,
  Container,
  CircularProgress,
} from "@mui/material";

// custom components
import { CustomPhoneField } from "../../atoms";

// css *required
import { useStyles } from "./index.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

//===================================================
// 2.Main Component
//===================================================
const UserDetails = (props) => {
  // 2-1. useStyles *require
  const classes = useStyles();
  const navigate = useNavigate();
  const [userAddress, setUserAddress] = useState();

  // 2-2. Store
  const { userInfo, address } = GetAuthValues();

  const handleBackClick = useCallback(() => navigate("/"), [navigate]);

  useEffect(() => {
    if (!userInfo) {
      return navigate("/");
    }

    const userAddress = address.find(
      (address) => address.userId === userInfo._id
    );

    setUserAddress(userAddress);
    return () => {
      navigate("/");
    };
  }, [navigate, userInfo, address]);

  // showMain
  return showMain({
    userInfo,
    userAddress,
    classes,
    handleBackClick,
  });
};

//===================================================
// 3.Export
//===================================================
export default UserDetails;

//===================================================
// 4.propTypes and defaultProps
//===================================================

//4-1. propTypes
UserDetails.propTypes = {};

//4-2. defaultProps
UserDetails.defaultProps = {};

//===================================================
// 5.Functions *require showMain()
//===================================================
/**
 * Show main
 * @param {*} props
 */
const showMain = (props) => {
  const { userInfo, classes, userAddress, handleBackClick } = props;
  return (
    <Container className={classes.container} maxWidth="md">
      <Button onClick={handleBackClick}>{"<"} Back to Home</Button>
      <Container className={classes.headerText}>
        <Typography variant="h5">User Information</Typography>
      </Container>
      <Grid container>
        <Grid item sm={8} xs={12}>
          <Grid container direction="row" className={classes.row}>
            <Grid item xs={6}>
              <Typography>Name:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {userInfo?.firstname} {userInfo?.lastname}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" className={classes.row}>
            <Grid item xs={6}>
              <Typography>Email: </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography noWrap>{userInfo?.email}</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" className={classes.row}>
            <Grid item xs={6}>
              <Typography>Contact:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{userInfo?.phone}</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" className={classes.row}>
            <Grid item xs={6}>
              <Typography>Verified Email:</Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ color: userInfo?.is_email_verified ? "green" : "red" }}
            >
              <Typography>
                {userInfo?.is_email_verified ? "Yes" : "No"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={8} xs={12}>
          <Grid container direction="row" className={classes.row}>
            <Grid item xs={6}>
              <Typography>Country:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{userAddress?.country}</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" className={classes.row}>
            <Grid item xs={6}>
              <Typography>Street: </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{userAddress?.street}</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" className={classes.row}>
            <Grid item xs={6}>
              <Typography>Suburb:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{userAddress?.suburb}</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" className={classes.row}>
            <Grid item xs={6}>
              <Typography>State:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{userAddress?.state}</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" className={classes.row}>
            <Grid item xs={6}>
              <Typography>Zipcode:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{userAddress?.zipcode}</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" className={classes.row}>
            <Grid item xs={6}>
              <Typography>Full Address:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{userAddress?.fullAddress}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

//===================================================
// 6.Actions
//===================================================