import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  navButton: {
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
  footerNav: {
    position: "relative",
    maxWidth: "96% !important",
    left: "0",
    bottom: "0",
    color: "#1976d2",
    textAlign: "center",
    padding: "10px",
  },
}));
