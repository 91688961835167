import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  navButton: {
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
  fullWith: {
    maxWidth: "96% !important",
  },
}));
