import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1rem !important",
  },
  container: {
    marginTop: "1rem",
    borderRadius: "5px",
    boxShadow: "1px 1px 5px lightgrey",
    padding: "2rem",
  },
  headerText: {
    // justifyContent: "center",
    textAlign: "center",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  row: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
}));