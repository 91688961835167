import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  dialogActions: {
    padding: "20px 24px !important",
  },
  hidden: {
    display: "none",
  },
  customPaddingTop: {
    paddingTop: "4px !important",
  },
}));
