//===================================================
// 1.Load,Init,Settings
//===================================================
import axios from "axios";
import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// Material-ui
import { Container, Paper, Box, Typography, Link } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";

// custom components
import WithCenteredLogoTemplate from "../../templates/WithCenteredLogoTemplate";

// css *required
import { useStyles } from "./index.css";

//===================================================
// 2.Main Component
//===================================================
const ThankYouMessage = (props) => {
  // 2-1. useStyles *require
  const classes = useStyles();

  // 2-2. Store
  const navigate = useNavigate();
  const backToBookingPage = () => {
    navigate("/");
  };

  // showMain
  return showMain({
    ...props,
    classes,
    backToBookingPage,
  });
};

//===================================================
// 3.Export
//===================================================
export default ThankYouMessage;

//===================================================
// 4.propTypes and defaultProps
//===================================================

//4-1. propTypes
ThankYouMessage.propTypes = {};

//4-2. defaultProps
ThankYouMessage.defaultProps = {};

//===================================================
// 5.Functions *require showMain()
//===================================================
/**
 * Show main
 * @param {*} props
 */
const showMain = (props) => {
  return <WithCenteredLogoTemplate children={thankYouTemplate(props)} />;
};

const thankYouTemplate = (props) => {
  const { backToBookingPage } = props;
  return (
    <Container align="center" maxWidth="sm">
      <Box sx={{ mt: 7, mb: 12 }}>
        <Paper
          background="light"
          sx={{ py: { xs: 4, md: 8 }, px: { xs: 3, md: 6 } }}
        >
          <CheckCircleOutline color="success" sx={{ fontSize: 130 }} />
          <Typography variant="body1" gutterBottom component="div">
            Thank you for booking with us. Our customer service will contact you
            soon to confirm the details.
          </Typography>
          <Link component="button" variant="body2" onClick={backToBookingPage}>
            Back
          </Link>
        </Paper>
      </Box>
    </Container>
  );
};

//===================================================
// 6.Actions
//===================================================
