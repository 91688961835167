//===================================================
// 1.Load,Init,Settings
//===================================================
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ACCEPT_BOOKING } from "../../../common/Constants";

// Material-ui
import {
  Container,
  Paper,
  Box,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";

// custom components
import WithCenteredLogoTemplate from "../../templates/WithCenteredLogoTemplate";

// css *required
import { useStyles } from "./index.css";

//===================================================
// 2.Main Component
//===================================================
const AcceptBooking = (props) => {
  // 2-1. useStyles *require
  const classes = useStyles();

  // 2-2. Store
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { mobileNumber, bookingId } = useParams();

  useEffect(() => {
    const acceptBooking = async () => {
      try {
        setIsLoading(true);
        const acceptData = {
          cleanersMobileNo: mobileNumber,
          bookingId: bookingId,
          status: ACCEPT_BOOKING,
          reason: "",
        };
        const response = await axios.post("/api/cleaner-status", acceptData);
        if (response.status === 200) {
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    acceptBooking();
  });
  // showMain
  return showMain({
    ...props,
    classes,
    navigate,
    isLoading,
    setIsLoading,
  });
};

//===================================================
// 3.Export
//===================================================
export default AcceptBooking;

//===================================================
// 4.propTypes and defaultProps
//===================================================

//4-1. propTypes
AcceptBooking.propTypes = {};

//4-2. defaultProps
AcceptBooking.defaultProps = {};

//===================================================
// 5.Functions *require showMain()
//===================================================
/**
 * Show main
 * @param {*} props
 */
const showMain = (props) => {
  return <WithCenteredLogoTemplate children={thankYouTemplate(props)} />;
};

const thankYouTemplate = (props) => {
  const { isLoading } = props;
  return (
    <Container align="center" maxWidth="sm">
      <Box sx={{ mt: 7, mb: 12 }}>
        <Paper
          background="light"
          sx={{ py: { xs: 4, md: 8 }, px: { xs: 3, md: 6 } }}
        >
          {isLoading ? (
            <CircularProgress disableShrink />
          ) : (
            showSuccessMessage(props)
          )}
        </Paper>
      </Box>
    </Container>
  );
};

const showSuccessMessage = (props) => {
  const { navigate } = props;
  return (
    <>
      <CheckCircleOutline color="success" sx={{ fontSize: 130 }} />
      <Typography variant="body1" gutterBottom component="div">
        Thank you for accepting the job. We will call you as soon as possible so
        we can give you more details and instructions
      </Typography>
      <Button variant="contained" sx={{ mt: 4 }} onClick={() => navigate("/")}>
        Back to homepage
      </Button>
    </>
  );
};

//===================================================
// 6.Actions
//===================================================
