import { JsonForms } from "@jsonforms/react";
import {
  materialRenderers,
  materialCells,
} from "@jsonforms/material-renderers";
import addressInputControlTester from "./CustomInputTester";
import AddressInputControl from "./CustomInputControl";
import { ValidationMode } from "@jsonforms/core";

const renderers = [
  ...materialRenderers,
  { tester: addressInputControlTester, renderer: AddressInputControl },
];

const CustomForm = ({ data, schema, uischema, onChange, validationMode }) => {
  return (
    <div>
      <JsonForms
        data={data}
        schema={schema}
        uischema={uischema}
        renderers={renderers}
        cells={materialCells}
        onChange={({ data, errors }) => onChange({ data, errors })}
        validationMode={validationMode ? validationMode : "NoValidation"}
      />
    </div>
  );
};

export default CustomForm;
