//===================================================
// 1.Load,Init,Settings
//===================================================
import React from "react";

// Material-ui
import {
  Container, 
  Typography,
  Box,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";

// custom components
import logo from "../../../assets/cleaningly-logo-admin-1.png";

// css *required
import { useStyles } from "./index.css";

//===================================================
// 2.Main Component
//===================================================
const CompanyLogo = (props) => {
  // 2-1. useStyles *require
  const classes = useStyles();

  // 2-2. Store

  // showMain
  return showMain({
    ...props,
    classes,
  });
};

//===================================================
// 3.Export
//===================================================
export default CompanyLogo;

//===================================================
// 4.propTypes and defaultProps
//===================================================

//4-1. propTypes
CompanyLogo.propTypes = {};

//4-2. defaultProps
CompanyLogo.defaultProps = {};

//===================================================
// 5.Functions *require showMain()
//===================================================
/**
 * Show main
 * @param {*} props
 */
const showMain = (props) => {
  const { classes } = props;
  return (
    <Container sx={{ m: "0", px: "0 !important", py: ".5rem" }}>
      <Box sx={{ mb: "0" }}>
        <Card variant="outlined" className={classes.noBorder}>
          <CardMedia
            component="img"
            image={logo}
            alt="green iguana"
            sx={{
              width: {
                xs: 100,
                sm: 100,
                md: 200,
                lg: 200,
                xl: 200,
              },
            }}
          />
          <CardContent className={classes.noPadding}>
            <Typography
              variant="h6"
              component="div"
              sx={{
                fontSize: {
                  xs: 8,
                  sm: 8,
                  md: 10,
                  lg: 11,
                  xl: 10,
                },
                width: {
                  xs: 100,
                  sm: 100,
                  md: 200,
                  lg: 300,
                  xl: 300,
                },
              }}
            >
              Cleaningly Home Services Pty Ltd (ABN: 67 651 940 797)
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

//===================================================
// 6.Actions
//===================================================
