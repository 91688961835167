import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1rem !important",
  },
  container: {
    padding: "0 !important",
  },
  // customLabel: {
  //   "& .MuiFormControlLabel-label": {
  //     fontSize: "15px !important",
  //   },
  // },
}));
