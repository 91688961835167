import { createSlice } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
//===================================================
// Initial State
//===================================================
const initialState = {
  isLoading: false,
  accessToken: null,
  error: null,
  user: null,
  isLoginSuccess: false,
};

//===================================================
// Slice
//===================================================

const serverUrl = process.env.REACT_APP_BACKEND_URL;
axios.defaults.baseURL = serverUrl;

export const Slice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    // here request is action
    request(state) {
      return {
        ...state,
        isLoading: true,
        accessToken: null,
        error: null,
      };
    },
    // here success is action
    success(state, action) {
      return {
        ...state,
        isLoading: false,
        accessToken: action.payload.access_token,
        user: action.payload.user,
        address: action.payload.address,
        error: null,
      };
    },
    failure(state, action) {
      return {
        ...state,
        isLoading: false,
        accessToken: null,
        error: action.payload.error,
        user: null,
      };
    },
    reset(state) {
      return {
        ...state,
        isLoading: false,
        accessToken: null,
        address: null,
        error: null,
        user: null,
      };
    },
    setIsLoginSuccess(state, action) {
      return {
        ...state,
        isLoginSuccess: action.payload,
      };
    },
  },
});

//===================================================
// Actions
//===================================================
export const { request, success, failure, reset, setIsLoginSuccess } =
  Slice.actions;

// For outside functional components call
export const GetOutsideAuthActions = () => {
  return {
    loginUser: async (args, dispatch) => {
      const { email, password } = args;
      dispatch(request());
      try {
        // Make api call and get response
        // Example response
        const response = await axios.post("/api/login", {
          email: email,
          password: password,
        });

        const userData = {
          access_token: response.data.token,
          user: response.data.user,
          address: response.data.address,
          isLoading: false,
          error: null,
        };
        // Save response to redux store
        return dispatch(success(userData));
      } catch (err) {
        // If api call fails save response accordingly
        return dispatch(failure({ error: err }));
      }
    },
    logout: async (dispatch) => {
      try {
        // Call api for logout, then reset redux store
        dispatch(reset());
      } catch (err) {
        return dispatch(failure({ error: err }));
      }
    },
  };
};

//===================================================
// Reducer for store
//===================================================
// Reducer values can be obtained using useSelector hooks
export const GetAuthValues = () => {
  return {
    accessToken: useSelector(({ auth }) => auth.accessToken),
    error: useSelector(({ auth }) => auth.error),
    isLoading: useSelector(({ auth }) => auth.isLoading),
    userInfo: useSelector(({ auth }) => auth.user),
    address: useSelector(({ auth }) => auth.address),
  };
};

export const AuthReducer = Slice.reducer;
