//===================================================
// 1.Load,Init,Settings
//===================================================
import React from "react";
import { useNavigate } from "react-router-dom";

// Material-ui
import { Container, Paper, Box, Typography, Button } from "@mui/material";
import { SentimentDissatisfiedRounded } from "@mui/icons-material";

// custom components
import WithCenteredLogoTemplate from "../../templates/WithCenteredLogoTemplate";

// css *required
import { useStyles } from "./index.css";

//===================================================
// 2.Main Component
//===================================================
const PageNotFound = (props) => {
  // 2-1. useStyles *require
  const classes = useStyles();

  // 2-2. Store
  const navigate = useNavigate();

  const backToBookingPage = () => {
    navigate("/");
  };
  // showMain
  return showMain({
    ...props,
    classes,
    backToBookingPage,
  });
};

//===================================================
// 3.Export
//===================================================
export default PageNotFound;

//===================================================
// 4.propTypes and defaultProps
//===================================================

//4-1. propTypes
PageNotFound.propTypes = {};

//4-2. defaultProps
PageNotFound.defaultProps = {};

//===================================================
// 5.Functions *require showMain()
//===================================================
/**
 * Show main
 * @param {*} props
 */
const showMain = (props) => {
  return <WithCenteredLogoTemplate children={thankYouTemplate(props)} />;
};

const thankYouTemplate = (props) => {
  const { backToBookingPage } = props;
  return (
    <Container align="center" maxWidth="sm">
      <Box sx={{ mt: 7, mb: 12 }}>
        <Paper
          background="light"
          sx={{ py: { xs: 4, md: 8 }, px: { xs: 3, md: 6 } }}
        >
          <SentimentDissatisfiedRounded
            color="error"
            sx={{ fontSize: 100, fontWeight: 800 }}
          />
          <Typography variant="h4" gutterBottom component="div">
            Oops!, Page not found
          </Typography>
          <Typography variant="body1" gutterBottom component="div">
            We are very sorry for the inconvenience. It loooks like you're
            trying to access a page that has been deleted or never even existed.
          </Typography>
          <Button
            variant="contained"
            sx={{ mt: 4 }}
            onClick={backToBookingPage}
          >
            Back to homepage
          </Button>
        </Paper>
      </Box>
    </Container>
  );
};

//===================================================
// 6.Actions
//===================================================
