import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import axios from "axios";

const serverUrl = process.env.REACT_APP_BACKEND_URL;
axios.defaults.baseURL = serverUrl;
//===================================================
// Initial State
//===================================================
const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

//===================================================
// Slice
//===================================================
export const Slice = createSlice({
  name: "register",
  initialState: initialState,
  reducers: {
    // here request is action
    request(state) {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    },
    // here success is action
    success(state, action) {
      return {
        ...state,
        isLoading: false,
        error: null,
        data: action.payload.data,
      };
    },
    failure(state, action) {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        data: null,
      };
    },
    reset(state) {
      return {
        ...state,
        isLoading: false,
        error: null,
        data: null,
      };
    },
  },
});

//===================================================
// Actions
//===================================================
export const { request, success, failure, reset } = Slice.actions;

// For outside functional components call
export const GetOutsideRegisterActions = () => {
  return {
    register: async (args, dispatch) => {
      dispatch(request());
      try {
        try {
          // Make api call and get response
          // Example response
          const response = await axios.post("/api/users", args);
          // Save response to redux store
          return dispatch(success(response));
        } catch (err) {
          // If api call fails save response accordingly
          return dispatch(failure({ error: err }));
        }
      } catch (err) {
        // If api call fails save response accordingly
        return dispatch(failure({ error: err }));
      }
    },
  };
};

//===================================================
// Reducer for store
//===================================================
// Reducer values can be obtained using useSelector hooks
export const GetRegisterValue = () => {
  return {
    error: useSelector(({ register }) => register.error),
    isLoading: useSelector(({ register }) => register.isLoading),
    data: useSelector(({ register }) => register.data),
  };
};

export const RegisterReducer = Slice.reducer;
