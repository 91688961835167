import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  noPaddingLeft: {
    paddingLeft: "0 !important",
  },
  hidden: {
    display: "none",
  },
  customPaddingTop: {
    paddingTop: "4px !important",
  },
}));
