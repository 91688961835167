import React, { useEffect } from "react";

import ReactGa from "react-ga";

import { useLocation } from "react-router-dom";

const GoogleAnalytics = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    ReactGa.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
  }, []);

  useEffect(() => {
    ReactGa.pageview(pathname);
  }, [pathname]);
  return <></>;
};

export default GoogleAnalytics;
