import Geocode from "react-geocode";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);

Geocode.setLanguage("en");

export const getLatLong = async (address) => {
  // Get latitude & longitude from address.
  const response = await Geocode.fromAddress(address);
  if (response.status === "OK") {
    return response.results[0].geometry.location;
  }
};
