import React from "react";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
  Navigate,
} from "react-router-dom";

// pages
import {
  AcceptBooking,
  Home,
  ThankYouMessage,
  PageNotFound,
  Invoice,
  PaymentSuccess,
  EmailVerified,
  ResetPassword,
  BookingFinal,
} from "./components/pages";
import { NavBar, Footer } from "./components/atoms";

// customer chat plug
import CustomerChat from "./components/molecules/CustomerChat";

// google analytics
import GoogleAnalytics from "./components/molecules/GoogleAnalytics";
import FormTest from "./components/pages/FormTest";

// profile
import Profile from "./components/pages/Profile";

const WithOutNavBar = () => <Outlet />;

const WithNavBar = () => (
  <>
    <NavBar />
    <Outlet />
  </>
);

const App = () => {
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <Routes>
              <Route element={<WithOutNavBar />}>
                <Route path="/thankyou" element={<ThankYouMessage />} />
                <Route path="/email-verified/:id" element={<EmailVerified />} />
                <Route path="/reset-password/:id" element={<ResetPassword />} />
                <Route
                  path="/accept-booking/:mobileNumber/:bookingId"
                  element={<AcceptBooking />}
                />
              </Route>
              <Route element={<WithNavBar />}>
                <Route path="/" exact strict element={<Home />} />
              </Route>
              <Route element={<WithNavBar />}>
                <Route path="/form-test" exact element={<FormTest />} />
              </Route>
              <Route path="/invoice/:id" exact element={<Invoice />} />
              <Route path="/payment/:id" exact element={<PaymentSuccess />} />
              <Route path="/booking/:id" exact element={<BookingFinal />} />

              <Route path="/404" exact element={<PageNotFound />} />
              <Route path="*" element={<Navigate replace to="/404" />} />
              <Route element={<WithNavBar />}>
                <Route path="/profile" exact element={<Profile />} />
              </Route>
            </Routes>
            <GoogleAnalytics />
          </Router>
          <CustomerChat />
          <Footer />
        </PersistGate>
      </Provider>
    </>
  );
};

export default App;
