//===================================================
// 1.Load,Init,Settings
//===================================================
import React from "react";

// Material-ui
import { Container } from "@mui/material";

// custom components
import { BookingForm } from "../../molecules";
import WithCenteredLogoTemplate from "../../templates/WithCenteredLogoTemplate";

// css *required
import { useStyles } from "./index.css";

//===================================================
// 2.Main Component
//===================================================
const Home = (props) => {
  // 2-1. useStyles *require
  const classes = useStyles();

  // 2-2. Store

  // showMain
  return showMain({
    ...props,
    classes,
  });
};

//===================================================
// 3.Export
//===================================================
export default Home;

//===================================================
// 4.propTypes and defaultProps
//===================================================

//4-1. propTypes
Home.propTypes = {};

//4-2. defaultProps
Home.defaultProps = {};

//===================================================
// 5.Functions *require showMain()
//===================================================
/**
 * Show main
 * @param {*} props
 */
const showMain = (props) => {
  const { classes } = props;
  return (
    <>
      {/* <WithCenteredLogoTemplate children={<BookingForm />} /> */}
      <Container className={classes.fullWith} maxWidth={false}>
        <BookingForm />
      </Container>
    </>
  );
};

//===================================================
// 6.Actions
//===================================================
