//===================================================
// 1.Load,Init,Settings
//===================================================
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Material-ui
import {
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  Paper,
  Box,
  Link,
} from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";

// custom components
import WithCenteredLogoTemplate from "../../templates/WithCenteredLogoTemplate";

// css *required
import { useStyles } from "./index.css";
// import {
//     TextField,
//     Button,
//     Typography,
//     Grid,
//     Container
//   } from "@material-ui/core";
//===================================================
// 2.Main Component
//===================================================
const ResetPassword = (props) => {
  // 2-1. useStyles *require
  const classes = useStyles();

  // 2-2. Store
  const navigate = useNavigate();

  // showMain
  return showMain({
    ...props,
    classes,
  });
};

//===================================================
// 3.Export
//===================================================
export default ResetPassword;

//===================================================
// 4.propTypes and defaultProps
//===================================================

//4-1. propTypes
ResetPassword.propTypes = {};

//4-2. defaultProps
ResetPassword.defaultProps = {};

//===================================================
// 5.Functions *require showMain()
//===================================================
/**
 * Show main
 * @param {*} props
 */
const showMain = (props) => {
  return <WithCenteredLogoTemplate children={ResetPasswordForm(props)} />;
};

const ResetPasswordForm = (props) => {
  const { id } = useParams();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const backToBookingPage = () => {
    navigate("/");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setError(" ");
    // validate passwords match and are at least 6 characters long
    if (password === confirmPassword && password.length >= 6) {
      // send new password to
      const resetPasswordRequest = async () => {
        try {
          const response = await axios.post(`/api/users/reset-password`, {
            id,
            password,
            confirmPassword,
          });
          console.log(response);
          if (response.data?.message === "Successfully updated password.") {
            setSuccess(true);
          } else {
            setError("Error 400: Unable to reset password.");
          }
        } catch (error) {
          console.log(error);
          setError("Error 400: Unable to reset password.");
        }
      };

      resetPasswordRequest();
    } else {
      if (password !== confirmPassword) {
        setError("Passwords do not match");
      }

      if (password.length < 6) {
        setError("Password length minimum is 6 characters");
      }
    }
  };

  return (
    <Container maxWidth="xs">
      <Typography variant="h4" align="center" gutterBottom>
        Change Password
      </Typography>
      {success ? (
        <Container align="center" maxWidth="sm">
          <Box sx={{ mt: 7, mb: 12 }}>
            <Paper
              background="light"
              sx={{ py: { xs: 4, md: 8 }, px: { xs: 3, md: 6 } }}
            >
              <CheckCircleOutline color="success" sx={{ fontSize: 130 }} />
              <Typography variant="body1" gutterBottom component="div">
                Password Reset Successfull!
              </Typography>
              <Link
                component="button"
                variant="body2"
                onClick={backToBookingPage}
              >
                Home
              </Link>
            </Paper>
          </Box>
        </Container>
      ) : (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="New Password"
                type="password"
                id="password"
                error={error.length > 1 ? true : false}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                error={error.length > 1 ? true : false}
                helperText={error}
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{ marginTop: "20px" }}
          >
            Change Password
          </Button>
        </form>
      )}
    </Container>
  );
};

//===================================================
// 6.Actions
//===================================================
