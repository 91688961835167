import { FormLabel, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { withJsonFormsControlProps } from "@jsonforms/react";

const AddressInput = ({ value, updateValue, label, description, errors }) => {
  const [focused, setFocused] = useState(false);
  const country = "au";
  const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const { ref } = usePlacesWidget({
    apiKey: key,
    onPlaceSelected: (place) => {
      const { formatted_address } = place;
      const splittedAddress = formatted_address.split(", ");
      const splittedSecondAddress = splittedAddress[1].split(" ");
      const selectedAddress = {
        zipcode: splittedSecondAddress.splice(-1)[0],
        state: splittedSecondAddress.splice(-1)[0],
        suburb: splittedSecondAddress.join(" "),
        fullAddress: formatted_address,
        longitude: place.geometry.location.lng(),
        latitude: place.geometry.location.lat(),
        country: "Australia",
        street: splittedAddress[0],
      };
      updateValue(selectedAddress?.fullAddress);
    },
    inputAutocompleteValue: "country",
    options: {
      types: ["address"],
      componentRestrictions: { country },
    },
  });

  return (
    <div>
      <TextField
        inputRef={ref}
        label={label}
        helperText={errors ? errors : focused ? description : null}
        variant="standard"
        value={value}
        fullWidth
        error={errors ? true : false}
        onChange={(e) =>
          updateValue(e.target.value === "" ? undefined : e.target.value)
        }
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        InputLabelProps={{
          shrink:
            focused || String(value ? value : "").length > 0 ? true : false,
        }}
      />
    </div>
  );
};

export default AddressInput;
