import { configureStore, combineReducers } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

//===================================================
// Import Reducer
//===================================================
import { AuthReducer } from "../modules/Auth";
import { RegisterReducer } from "../modules/Register";
import { BookReducer } from "../modules/Book";
// import {TasksReducer} from '../modules/Tasks';

//===================================================
// redux-presist
//===================================================
const authPersistConfig = {
  key: "auth",
  storage,
};

//===================================================
// Export store
//===================================================
export const store = configureStore({
  reducer: combineReducers({
    // persist
    /**
     * Use persist reducer for which you want to save data
     * even after closing app
     */
    auth: persistReducer(authPersistConfig, AuthReducer),
    // not persist
    /**
     * These data won't be saved after you close app
     */
    // products: ProductReducer,
    register: RegisterReducer,
    book: BookReducer,
    // tasks: TasksReducer,
  }),
  middleware: [thunk],
});

//===================================================
// Export presistor
//===================================================
export const persistor = persistStore(store);
